<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <b-card header-tag="header" footer-tag="footer">
          <b-card-text>
            <div>
              <b-form-group label="Posible proveedor">
                <a-multiselect
                  v-model="form.object_provider"
                  track-by="id"
                  label="social_name"
                  placeholder="Seleccione proveedor"
                  :options="providers"
                  :allow-empty="true"
                >
                </a-multiselect>
              </b-form-group>
              <b-form-group label="Área que debe cotizar">
                <b-form-checkbox-group v-model="form.areas">
                  <b-form-checkbox size="lg" value="ING">
                    Ingeniería
                  </b-form-checkbox>
                  <b-form-checkbox size="lg" value="LOG" class="mb-3">
                    Logística
                  </b-form-checkbox>
                </b-form-checkbox-group>
              </b-form-group>
            </div>
          </b-card-text>

          <template v-slot:footer>
            <b-button size="sm" variant="primary" @click="saveItem()">
              OK
            </b-button>
          </template>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import "vue-multiselect/dist/vue-multiselect.min.css";

export default {
  data() {
    return {
      providers: [],
      form: { areas: ["ING", "LOG"] }
    };
  },
  methods: {
    getProviders() {
      ApiService.get("api/provider").then(response => {
        this.providers = response.data;
        console.log(this.providers);
      });
    },
    saveItem() {
      this.form.provider = this.form.object_provider.id;
      ApiService.post("api/quote-request/", this.form)
        .then(response => {
          this.$bvToast.toast("Se ha creado el item correctamente", {
            title: `Éxito`,
            variant: "success",
            solid: true
          });
          setTimeout(
            () =>
              this.$router.push({
                name: "maintenances_quote_request_edit",
                params: { id: response.id }
              }),
            1000
          );
        })
        .catch(() => {
          this.$bvToast.toast("Ha ocurrido un error", {
            title: `Error`,
            variant: "danger",
            solid: true
          });
        });
    }
  },
  created() {
    this.getProviders();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Mantenimientos" },
      { title: "Solicitudes de cotización" },
      { title: "Crear" }
    ]);
  }
};
</script>
